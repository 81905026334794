import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { DangerousComponentInjector } from '../../DangerousComponentInjector';
import { CcTypography } from '../../CcTypography';
import { useHeaderAnimation } from './useHeaderAnimation';
import { CcBox } from '../../CcBox';

type Props = {
  children?: React.ReactNode;
  title?: string;
  titleParams?: unknown;
};

const useStyles = makeStyles({
  title: {
    position: 'relative',
    zIndex: 8,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',

    '& .fixed': {
      '&:before': {
        content: '""',
        height: '160px',
        backgroundImage: 'linear-gradient(to bottom, #fcfdfd 0%, rgba(252,253,253,1) 64%, rgba(252,253,253,0) 100%)',
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        zIndex: -1,
        pointerEvents: 'none',
      },
      '& h1': {
        position: 'fixed',
        top: '60px',
        right: 0,
        left: 0,
        width: 'inherit',
      },
    },

    '& .small-font': {
      '& h1': {
        fontSize: '30px',
        minHeight: 'auto',
        letterSpacing: '-0.5px',
      },
    },
  },
});

export const ApplicationTitle = ({
  children,
  title,
  titleParams,
}: Props) => {
  const styles = useStyles();
  const headerRef = useHeaderAnimation();

  return (
    <CcBox
      padding="s"
      className={cx(styles.title)}
    >
      <div
        ref={headerRef}
      >
        {
        title && (
          <DangerousComponentInjector
            component={CcTypography}
            variant="h1"
            text={title}
            params={titleParams}
            data-test="application title"
          />
        )
      }
        {children}
      </div>
    </CcBox>
  );
};
