import {
  getAllyApproveDisclosureAction,
  getAllyDisclosureAction,
  getAllySsnAction,
  getPowerPaySignatureAction,
  triggerAlphaeonVerificationAction,
  validateCardAction,
} from './actionTypes';
import { defThunkRequest } from '../../utils/defAsyncAction';
import {
  getAllyApproveDisclosureRequest,
  getAllyDisclosuresRequest,
  getAllySsnRequest,
  getPowerPaySignature,
  IValidateCard,
  triggerAlphaeonVerificationRequest,
  validateCardRequest,
} from '../apiRequests';
import { AllyDisclosure } from '../../types/allyDisclosure';

export const validateCardThunk = defThunkRequest<{
  id: string;
  body: IValidateCard;
}>({
  actionTypes: validateCardAction,
  thunkSteps: [
    ({
      data: { id, body },
    }) => validateCardRequest(id, body),
  ],
});

export const getPowerPaySignatureThunk = defThunkRequest<{
  id: string;
}>({
  actionTypes: getPowerPaySignatureAction,
  thunkSteps: [
    ({
      data: { id },
    }) => getPowerPaySignature(id),
  ],
});

export const getAllyDisclosureThunk = defThunkRequest<{
  locationId: string;
}, Array<AllyDisclosure>>({
  actionTypes: getAllyDisclosureAction,
  thunkSteps: [
    ({
      data: { locationId },
    }) => getAllyDisclosuresRequest(locationId),
  ],
});
export const getAllyApproveDisclosureThunk = defThunkRequest<{
  applicationId: string;
}, {disclosures: Array<AllyDisclosure>}>({
  actionTypes: getAllyApproveDisclosureAction,
  thunkSteps: [
    ({
      data: { applicationId },
    }) => getAllyApproveDisclosureRequest(applicationId),
  ],
});

export const getAllySsnThunk = defThunkRequest<{
  locationId: string;
}, {'ssn': 'string'}>({
  actionTypes: getAllySsnAction,
  thunkSteps: [
    ({
      data: { locationId },
    }) => getAllySsnRequest(locationId),
  ],
});

export const triggerAlphaeonVerificationThunk = defThunkRequest<{
  applicationId: string;
}>({
  actionTypes: triggerAlphaeonVerificationAction,
  thunkSteps: [
    ({
      data,
    }) => triggerAlphaeonVerificationRequest(data.applicationId),
  ],
});
