import {
  useMemo, useRef, useState,
} from 'react';

export type ModalControlProps<T> = [
  open: boolean,
  control: {
    open: (data?: T) => void,
    close: () => void,
  },
  data?: T,
];

type Props = {
  onClose?: () => void;
};

export const useModalControl = <T = unknown>(props?: Props): ModalControlProps<T> => {
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<T>();
  const onCloseRef = useRef<(() => void) | null | undefined>(null);

  if (onCloseRef.current !== props?.onClose) {
    onCloseRef.current = props?.onClose;
  }

  const controller = useMemo(() => {
    const handleOpen = (modalData?: T) => {
      setOpen(true);

      if (modalData) {
        setData(modalData);
      }
    };

    const handleClose = () => {
      setOpen(false);
      setData(undefined);

      if (onCloseRef.current) {
        onCloseRef.current();
      }
    };

    return { open: handleOpen, close: handleClose };
  }, []);

  return [open, controller, data];
};
