import React from 'react';
import {
  Dialog,
} from '@material-ui/core';
import cx from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    background: 'rgba(9, 2, 36, 0.7)',
    textAlign: 'center',
  },
  scrollPaper: {
    overflow: 'hidden',
  },
  paper: {
    position: 'relative',
    width: '100%',
    maxWidth: ({ customWidth }: Partial<Props>) => (customWidth || 776),
    maxHeight: '100%',
    border: '0.6px solid #E5EAF9',
    boxShadow: '0px 2px 9px rgba(127, 147, 174, 0.1)',
    borderRadius: 16,
    padding: 'var(--spacing-m)',
    [theme.breakpoints.down('xs')]: {
      maxWidth: ({ mobileCustomWidth }: Partial<Props>) => (mobileCustomWidth || 375),
      padding: 24,
      margin: 0,
    },
  },
}));

type Props = {
    onClose?: Function;
    children: React.ReactNode;
    customWidth?: number | string;
    mobileCustomWidth?: number| string;
    classes?: any;
};

export const BaseModal = ({
  onClose,
  children,
  customWidth,
  mobileCustomWidth,
  classes = {},
}: Props) => {
  const styles = useStyles({ customWidth, mobileCustomWidth });

  return (
    <Dialog
      open
      onClose={onClose as any}
      classes={{
        root: cx(styles.root, classes.root),
        scrollPaper: cx(styles.scrollPaper, classes.scrollPaper),
        paper: cx(styles.paper, classes.paper),
      }}
      TransitionProps={{
        // @ts-ignore
        tabIndex: '',
      }}
    >
      {children}
    </Dialog>
  );
};
